import useSWR from 'swr';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Checkbox, Flex, Text, Textarea } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import ErrorMessageIcon from '../../../../assets/Icons/ErrorMessageIcon';
import { ModalType } from '../../../../enum/ModalType';
import { SWRKey } from '../../../../enum/SWRKey';
import { getAdminProductById, getDealsProducts } from '../../../../services/product';
import { useAppDispatch } from '../../../../store/hooks';
import { showModal } from '../../../../store/modules/ui';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';
import ErrorScreen from '../../../shared/ErrorScreen/ErrorScreen';
import FileInput from '../../../shared/FileInput/FileInput';
import EditProductSkeleton from './EditProductSkeleton';
import { AddProductImageValidation } from './validations';

export interface AddProductImageFormValues {
  productImageUrl: string;
  description: string;
  dealSinsacate: boolean;
  dealPocitos: boolean;
  showDealSinsacate: boolean;
  showDealPocitos: boolean;
}

const EditProduct = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();

  const { data, error, isLoading } = useSWR(
    productId && { productId, key: SWRKey.adminProduct },
    getAdminProductById,
  );

  const { data: dealsData } = useSWR({ key: SWRKey.adminDealsProduct }, getDealsProducts);

  const {
    register,
    getValues,
    setValue,
    clearErrors,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm<AddProductImageFormValues>({
    defaultValues: {
      productImageUrl: data?.productImageUrl,
      description: data?.description,
      dealSinsacate: data?.dealSinsacate,
      dealPocitos: data?.dealPocitos,
      showDealSinsacate: data?.showDealSinsacate,
      showDealPocitos: data?.showDealPocitos,
    },
    mode: 'onChange',
    resolver: yupResolver(
      AddProductImageValidation(dealsData || { showDealSinsacate: [], showDealPocitos: [] }),
    ),
    values: {
      productImageUrl: data?.productImageUrl || '',
      description: data?.description || '',
      dealSinsacate: data?.dealSinsacate || false,
      dealPocitos: data?.dealPocitos || false,
      showDealSinsacate: data?.showDealSinsacate || false,
      showDealPocitos: data?.showDealPocitos || false,
    },
  });

  if (isLoading) {
    return <EditProductSkeleton />;
  }

  if (error || !data) {
    return <ErrorScreen title={textContent.product.associatedProductsNotFound} />;
  }

  return (
    <Box>
      <Box padding="24px 112px" backgroundColor="#FAFAFC" data-testid="container">
        {data && (
          <Flex alignItems="flex-start" textAlign="start">
            <ArrowBackIcon
              boxSize={8}
              marginRight="20px"
              onClick={() => navigate(-1)}
              cursor="pointer"
              data-testid="back-button"
            />
            <Box>
              <Text
                color="darkBlue"
                fontSize="20px"
                fontWeight="600"
                data-testid="back-button-title"
              >
                {data.articleDesc}
              </Text>
              <Text
                color="grey900"
                fontSize="14px"
                fontWeight="500"
                data-testid="back-button-sub-title"
              >
                {data.productId}
              </Text>
            </Box>
          </Flex>
        )}
      </Box>
      <Flex margin="40px 112px">
        <Box>
          <Flex
            justify="center"
            align="center"
            border="1px solid #E2E8F0"
            borderRadius="8px"
            width="160px"
            height="160px"
          >
            <FileInput
              clearErrors={clearErrors}
              haveImage={data.productImageUrl}
              havePreview
              iconTestId="image-input-icon"
              inputTestId="product-image-input"
              changeHandler={(value) => setValue('productImageUrl', value, { shouldDirty: true })}
              width="160px"
              height="160px"
              leftPosition="180px"
              maxSizeBytes={50 * 1024}
            />
          </Flex>
          {errors.productImageUrl ? (
            <Flex alignItems="center" maxWidth="300px" width="100%" marginTop="8px">
              <Box width="16px" height="16px">
                <ErrorMessageIcon dataTestId="error-message-icon" />
              </Box>
              <Text
                color="errorRed"
                fontFamily="Roboto"
                fontSize="12px"
                fontWeight="500"
                marginLeft="5px"
                data-testid="error-image"
              >
                {errors.productImageUrl.message}
              </Text>
            </Flex>
          ) : (
            <Flex height="26px" />
          )}
        </Box>
        <Box>
          <Textarea
            {...register('description')}
            placeholder={textContent.product.productDescription}
            marginLeft="32px"
            resize="none"
            height="80px"
            width="320px"
            borderRadius="8px"
            data-testid="product-description"
          />
          {errors.description && (
            <Flex
              alignItems="center"
              maxWidth="300px"
              width="100%"
              marginTop="8px"
              marginLeft="32px"
            >
              <Box width="16px" height="16px">
                <ErrorMessageIcon dataTestId="error-message-icon" />
              </Box>
              <Text
                color="errorRed"
                fontFamily="Roboto"
                fontSize="12px"
                fontWeight="500"
                marginLeft="5px"
                data-testid="error-description"
              >
                {errors.description.message}
              </Text>
            </Flex>
          )}
        </Box>
        <Flex flexDirection="column" paddingLeft="20px" justifyContent="space-between">
          <Box>
            <Checkbox
              {...register('dealSinsacate')}
              data-testid="dealSinsacate-checkbox"
              colorScheme="white"
              iconColor="#3377FF"
              borderColor="#3377FF"
            >
              {textContent.productDeals.dealSinsacate}
            </Checkbox>
            {errors.dealSinsacate && (
              <Flex
                alignItems="center"
                maxWidth="300px"
                width="100%"
                marginTop="8px"
                marginLeft="32px"
              >
                <Box width="16px" height="16px">
                  <ErrorMessageIcon dataTestId="error-message-icon" />
                </Box>
                <Text
                  color="errorRed"
                  fontFamily="Roboto"
                  fontSize="12px"
                  fontWeight="500"
                  marginLeft="5px"
                  data-testid="error-description"
                >
                  {errors.dealSinsacate.message}
                </Text>
              </Flex>
            )}
          </Box>
          <Box>
            <Checkbox
              {...register('dealPocitos')}
              data-testid="dealPocitos-checkbox"
              colorScheme="white"
              iconColor="#3377FF"
              borderColor="#3377FF"
            >
              {textContent.productDeals.dealPocitos}
            </Checkbox>
            {errors.dealPocitos && (
              <Flex
                alignItems="center"
                maxWidth="300px"
                width="100%"
                marginTop="8px"
                marginLeft="32px"
              >
                <Box width="16px" height="16px">
                  <ErrorMessageIcon dataTestId="error-message-icon" />
                </Box>
                <Text
                  color="errorRed"
                  fontFamily="Roboto"
                  fontSize="12px"
                  fontWeight="500"
                  marginLeft="5px"
                  data-testid="error-description"
                >
                  {errors.dealPocitos.message}
                </Text>
              </Flex>
            )}
          </Box>
          <Box>
            <Checkbox
              {...register('showDealSinsacate')}
              data-testid="showDealSinsacate-checkbox"
              colorScheme="white"
              iconColor="#3377FF"
              borderColor="#3377FF"
            >
              {textContent.productDeals.showDealSinsacate}
            </Checkbox>
            {errors.showDealSinsacate && (
              <Flex
                alignItems="center"
                maxWidth="300px"
                width="100%"
                marginTop="8px"
                marginLeft="32px"
              >
                <Box width="16px" height="16px">
                  <ErrorMessageIcon dataTestId="error-message-icon" />
                </Box>
                <Text
                  color="errorRed"
                  fontFamily="Roboto"
                  fontSize="12px"
                  fontWeight="500"
                  marginLeft="5px"
                  data-testid="error-description"
                >
                  {errors.showDealSinsacate.message}
                </Text>
              </Flex>
            )}
          </Box>
          <Box>
            <Checkbox
              {...register('showDealPocitos')}
              data-testid="showDealPocitos-checkbox"
              colorScheme="white"
              iconColor="#3377FF"
              borderColor="#3377FF"
            >
              {textContent.productDeals.showDealPocitos}
            </Checkbox>
            {errors.showDealPocitos && (
              <Flex
                alignItems="center"
                maxWidth="300px"
                width="100%"
                marginTop="8px"
                marginLeft="32px"
              >
                <Box width="16px" height="16px">
                  <ErrorMessageIcon dataTestId="error-message-icon" />
                </Box>
                <Text
                  color="errorRed"
                  fontFamily="Roboto"
                  fontSize="12px"
                  fontWeight="500"
                  marginLeft="5px"
                  data-testid="error-description"
                >
                  {errors.showDealPocitos.message}
                </Text>
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        width="512px"
        marginLeft="112px"
        data-testid="button-container"
      >
        <Button
          content={textContent.cancel}
          data-testid="cancel-button"
          border="1px solid"
          borderColor="purple"
          color="purple"
          backgroundColor="background"
          maxWidth="155px"
          width="100%"
          onClick={() => navigate('/admin/products')}
        />
        <Button
          isDisabled={!isDirty}
          color="grey50"
          backgroundColor="purple"
          content={textContent.saveChanges}
          _hover={{ backgroundColor: 'darkPurple' }}
          _active={{ backgroundColor: 'none' }}
          _disabled={{ backgroundColor: 'lightPurple', pointerEvents: 'none' }}
          maxWidth="155px"
          width="100%"
          marginLeft="24px"
          data-testid="confirm-button"
          onClick={() => {
            trigger();
            if (isValid)
              dispatch(
                showModal({
                  type: ModalType.CONFIRM_PRODUCT_EDIT,
                  data: {
                    productId: productId as string,
                    isActive: true,
                    data: getValues(),
                    isNew: data?.isNew as boolean,
                  },
                }),
              );
          }}
        />
      </Flex>
    </Box>
  );
};

export default EditProduct;
