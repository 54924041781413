export enum SWRKey {
  generalSalesEmail = '#generalSalesEmail',
  branches = '#branches',
  homeBanners = '#homeBanners',
  adminBanners = '#adminBanners',
  adminProducts = '#adminProducts',
  adminProduct = '#adminProduct',
  adminDealsProduct = '#adminDealsProduct',
  reservationHistory = '#reservationHistory',
  reservationHistoryFilters = '#reservationHistoryFilters',
  brands = '#brands',
  homeDeals = '#homeDeals',
  homeOils = '#homeOils',
  homeOnTrip = '#homeOnTrip',
  product = '#product',
  products = '#products',
  // TODO: After migrating all the request to the `/` endpoint with the right filters
  // we can remove the following keys
  productsPendingToReceive = '#productsPendingToReceive',
  productsOnDeals = '#productsOnDeals',
  settings = '#settings',
}
