import React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Skeleton } from '@chakra-ui/react';

const EditProductSkeleton = () => {
  return (
    <Box>
      <Box padding="24px 112px" backgroundColor="#FAFAFC" data-testid="skeleton-container">
        <Flex alignItems="flex-start" textAlign="start">
          <ArrowBackIcon boxSize={8} marginRight="20px" data-testid="back-button" />
          <Box>
            <Skeleton width="200px" height="20px" />
            <Skeleton width="50px" height="14px" marginTop="8px" />
          </Box>
        </Flex>
      </Box>
      <Flex margin="40px 112px">
        <Skeleton width="160px" height="160px" borderRadius="8px" />
        <Skeleton width="320px" height="80px" marginLeft="32px" borderRadius="8px" />
        <Flex flexDirection="column" gap="30px">
          <Flex>
            <Skeleton width="30px" height="30px" marginLeft="32px" borderRadius="8px" />
            <Skeleton width="320px" height="30px" marginLeft="20px" borderRadius="8px" />
          </Flex>
          <Flex>
            <Skeleton width="30px" height="30px" marginLeft="32px" borderRadius="8px" />
            <Skeleton width="320px" height="30px" marginLeft="20px" borderRadius="8px" />
          </Flex>
          <Flex>
            <Skeleton width="30px" height="30px" marginLeft="32px" borderRadius="8px" />
            <Skeleton width="320px" height="30px" marginLeft="20px" borderRadius="8px" />
          </Flex>
          <Flex>
            <Skeleton width="30px" height="30px" marginLeft="32px" borderRadius="8px" />
            <Skeleton width="320px" height="30px" marginLeft="20px" borderRadius="8px" />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        width="512px"
        marginLeft="112px"
      >
        <Skeleton width="155px" height="40px" borderRadius="8px" />
        <Skeleton width="155px" height="40px" marginLeft="24px" borderRadius="8px" />
      </Flex>
    </Box>
  );
};

export default EditProductSkeleton;
