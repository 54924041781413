import * as yup from 'yup';

import { DealsArrays } from '../../../../store/modules/products/types';
import textContent from '../../../../utils/textContent.json';

export const AddProductImageValidation = (dealsData: DealsArrays) =>
  yup.object().shape({
    description: yup
      .string()
      .min(3, textContent.product.minDescription)
      .max(380, textContent.product.maxDescription)
      .required(textContent.product.fieldRequired),
    productImageUrl: yup
      .string()
      .required(textContent.product.fieldRequired)
      .typeError(textContent.product.fieldRequired),
    dealSinsacate: yup
      .boolean()
      .required(textContent.product.fieldRequired)
      .typeError(textContent.product.fieldRequired),
    dealPocitos: yup
      .boolean()
      .required(textContent.product.fieldRequired)
      .typeError(textContent.product.fieldRequired),
    showDealSinsacate: yup
      .boolean()
      .required(textContent.product.fieldRequired)
      .typeError(textContent.product.fieldRequired)
      .test('max-showDealSinsacate', textContent.product.maxDeals, function (value) {
        return value === false || dealsData?.showDealSinsacate.length < 6;
      }),
    showDealPocitos: yup
      .boolean()
      .required(textContent.product.fieldRequired)
      .typeError(textContent.product.fieldRequired)
      .test('max-showDealPocitos', textContent.product.maxDeals, function (value) {
        return value === false || dealsData?.showDealPocitos.length < 6;
      }),
  });
