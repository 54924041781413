import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios';

import { AuthResponse } from '../interfaces';
import { getLocalStorage, setLocalStorage } from '../utils/token';

export const URL = {
  GET_USER: '/user',
  LOGIN_USER: '/auth/user-login',
  AUTH_USER: '/auth/auth-user',
  REFRESH_TOKEN: '/auth/refresh',
  GET_CATEGORIES: '/category/',
  GET_CATEGORY_BY_ID: '/category/getCategory',
  GET_PRODUCTS: '/product',
  GET_STOCK: '/stock',
  GET_FILTERED_PRODUCTS: '/product/search',
  FAVORITE: '/favorite',
  VOUCHER: '/voucher',
  BRANCH: '/branch',
  GET_FILTERED_BRANCHES: '/branch/search',
  GET_ADMIN_VOUCHERS: '/admin/vouchers',
  GET_PAGINATED_PRODUCTS_BY_CATEGORY: 'product/byCategory',
  GET_PAGINATED_PRODUCTS_BY_DEALS: 'product/byDeals',
  GET_PAGINATED_PRODUCTS_PENDING_TO_RECEIVE: 'product/pendingToReceive',
  GET_PAGINATED_PRODUCTS_BY_BRAND: 'product/byBrand',
  QUOTATION: '/quotation',
  GET_HOME_PAGINATED_PRODUCTS: 'product/homeProducts',
  SETTINGS: '/settings',
  GET_USER_ROLE: '/userRole',
  DELETE_ADMIN_VOUCHERS: '/admin/vouchers',
  BANNER_CLIENT: '/banner',
  BANNER: 'admin/banner',
  MANUAL_DELETE: '/manualDeleteVoucher',
  GENERAL_SALES_EMAIL: 'admin/generalSalesEmail',
  USER_LOGOUT: '/auth/user-logout',
  BRAND: '/brand/byCategory',
  GET_DEALS_ADMIN: '/admin/deals',
};

const baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = getLocalStorage('access_token');
  if (config.headers && token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
});

interface NewAuthToken {
  accessToken: string;
  idToken: string;
}

const saveToken = ({ idToken, accessToken }: NewAuthToken) => {
  setLocalStorage('access_token', accessToken);
  setLocalStorage('id_token', idToken);
};

const getNewToken = async (): Promise<NewAuthToken> => {
  try {
    const refreshToken = getLocalStorage('refresh_token');
    const accessToken = getLocalStorage('access_token');
    const userId = getLocalStorage('user_id');
    const res = await post<AuthResponse>(`${URL.REFRESH_TOKEN}`, {
      refreshToken,
      accessToken,
      userId,
    });

    return {
      accessToken: res.data.access_token,
      idToken: res.data.id_token,
    };
  } catch (_error) {
    return Promise.reject(_error);
  }
};

let getNewTokenRequest: Promise<NewAuthToken> | undefined;

const resetGetNewTokenRequest = () => {
  getNewTokenRequest = undefined;
};

const refreshToken = () => {
  if (!getNewTokenRequest) {
    getNewTokenRequest = getNewToken();
    getNewTokenRequest.then(resetGetNewTokenRequest, resetGetNewTokenRequest);
  }

  return getNewTokenRequest;
};

axios.interceptors.response.use(
  async (res) => {
    if (res.status === 401) {
      const newAuthToken = await refreshToken();
      saveToken(newAuthToken);
      return axios(res.config);
    }

    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== URL.LOGIN_USER && err.response) {
      if (
        (err.response.status === 401 && !originalConfig._retry) ||
        err.response.data.message === '"exp" claim timestamp check failed'
      ) {
        originalConfig._retry = true;
        const newAuthToken = await refreshToken();
        saveToken(newAuthToken);
        return axios(originalConfig);
      }
    }

    return Promise.reject(err);
  },
);

export const getClient = (headers = {}): AxiosRequestConfig => {
  return {
    baseURL: baseURL + '/api',
    timeout: 120000,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  };
};

const isErrorResponse = (response: AxiosResponse) => {
  if (response.status < 200 || response.status > 299) {
    return true;
  }
  return false;
};

export const parseResponse = (response: AxiosResponse) => {
  if (isErrorResponse(response)) {
    const data = response.data;
    const parsedResponse = {
      ...data,
      message: response.data.message,
    };
    return Promise.reject(parsedResponse);
  }

  return response.data;
};

export const request = async <T>(
  method: Method,
  endpoint: string,
  data: object = {},
  headers?: AxiosRequestHeaders,
): Promise<T> => {
  const client = getClient(headers);
  if (method === 'GET') {
    return axios(endpoint, {
      ...client,
      method,
    }).then(parseResponse);
  }

  return axios(endpoint, {
    ...client,
    method,
    data: JSON.stringify(data),
  }).then(parseResponse);
};

export const get = async <T>(
  endpoint: string,
  signal?: AbortSignal,
  headers?: AxiosRequestHeaders,
): Promise<T> => {
  return request('GET', endpoint, signal, headers);
};

export const post = async <T>(
  endpoint: string,
  data: object = {},
  headers?: AxiosRequestHeaders,
): Promise<T> => {
  try {
    return await request('POST', endpoint, data, headers);
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 503) {
      window.location.reload();
    }
    return Promise.reject(error);
  }
};

export const patch = async <T>(
  endpoint: string,
  data: object = {},
  headers?: AxiosRequestHeaders,
): Promise<T> => {
  return request('PATCH', endpoint, data, headers);
};

export const put = async <T>(
  endpoint: string,
  data: object = {},
  headers?: AxiosRequestHeaders,
): Promise<T> => {
  return request('PUT', endpoint, data, headers);
};

export const del = async <T>(endpoint: string, headers?: AxiosRequestHeaders): Promise<T> => {
  return request('DELETE', endpoint, {}, headers);
};
