import useSWR from 'swr';
import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Select, Text } from '@chakra-ui/react';
import { PaginationState } from '@tanstack/react-table';

import AlertIcon from '../../../../assets/Icons/AlertIcon';
import { SWRKey } from '../../../../enum/SWRKey';
import { FilterType, ProductTableType } from '../../../../interfaces/products';
import { getAllProducts } from '../../../../services/product';
import {
  dealsStatus,
  dropdownColor,
  productStatus,
  SkeletonCells,
  updateFilter,
} from '../../../../utils/tableFunctions';
import textContent from '../../../../utils/textContent.json';
import SharedTable from '../../../shared/Table/SharedTable';
import SharedTableSkeleton from '../../../shared/Table/SharedTableSkeleton';
import { columns } from './columsDefinition';
import EmptyProductView from './EmptyProductView';

const ProductsView = () => {
  const [searchValue, setSearchValue] = useState('');

  const [filters, setFilters] = useState<FilterType>({
    brandValue: '',
    statusValue: '',
    dealValue: '',
  });

  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 5,
    pageIndex: 0,
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      pageIndex: 0,
    });
  }, [filters, searchValue]);

  const { data, error, isLoading } = useSWR(
    { pagination, filters, searchValue, key: SWRKey.adminProducts },
    getAllProducts,
  );

  const clearFilters = () => {
    setFilters({ brandValue: '', statusValue: '', dealValue: '' });
    setSearchValue('');
  };

  const Filters = useMemo(
    (): JSX.Element => (
      <>
        <Select
          data-testid="product-status-dropdown"
          fontSize="12px"
          width="157px"
          value={filters.statusValue}
          color={dropdownColor(filters.statusValue)}
          borderColor="#E2E8F0"
          onChange={(e) => {
            setFilters(() => updateFilter({ key: 'statusValue', value: e.target.value }, filters));
          }}
        >
          <option value="">{textContent.product.productStatus}</option>
          {productStatus.map((status) => {
            return (
              <option key={status} value={status}>
                {status}
              </option>
            );
          })}
        </Select>
        <Select
          data-testid="product-brand-dropdown"
          fontSize="12px"
          width="107px"
          value={filters.brandValue}
          color={dropdownColor(filters.brandValue)}
          borderColor="#E2E8F0"
          onChange={(e) => {
            setFilters(() => updateFilter({ key: 'brandValue', value: e.target.value }, filters));
          }}
        >
          <option value="">{textContent.product.productBrand}</option>
          {data &&
            data.brands.map((brand) => {
              return (
                <option key={brand} value={brand}>
                  {brand}
                </option>
              );
            })}
        </Select>
        <Select
          data-testid="product-deal-dropdown"
          fontSize="12px"
          width="107px"
          value={filters.dealValue}
          color={dropdownColor(filters.dealValue)}
          borderColor="#E2E8F0"
          onChange={(e) => {
            setFilters(() => updateFilter({ key: 'dealValue', value: e.target.value }, filters));
          }}
        >
          <option value="">{textContent.product.deals}</option>
          {dealsStatus.map((deal) => {
            return (
              <option key={deal} value={deal}>
                {deal}
              </option>
            );
          })}
        </Select>
        {Object.values(filters).some((value) => value !== '') && (
          <Button
            data-testid="clear-filters-button"
            variant="link"
            color="#B23E8F"
            fontSize="14px"
            fontWeight="500"
            onClick={() => clearFilters()}
          >
            {textContent.reservations.emptyFilters}
          </Button>
        )}
      </>
    ),
    [data, filters],
  );

  const TableContent = useMemo(() => {
    if (isLoading) {
      return <SharedTableSkeleton rows={7} cells={SkeletonCells} />;
    }

    return (
      <SharedTable
        data={data?.products as ProductTableType[]}
        columns={columns}
        filters={Filters}
        onPaginationChange={setPagination}
        pageCount={data?.totalPages ?? 0}
        total={data?.total ?? 0}
        pagination={pagination}
        headerAlign
        dataTestId="products-table-container"
        searchPlaceholder={textContent.product.productInputTable}
        searchType="text"
        error={error}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        searchBarWidth="325px"
        emptyComponent={<EmptyProductView title={textContent.product.productError} />}
      />
    );
  }, [Filters, data, error, isLoading, pagination, searchValue]);

  return (
    <Box>
      <Box padding="24px 112px" backgroundColor="#FAFAFC" data-testid="container">
        {isLoading || !(error || !data?.products?.length || !data) ? (
          <Flex height="51px" flexDirection="column">
            <Text data-testid="title" fontWeight="600" color="darkBlue" fontSize="20px">
              {textContent.product.products}
            </Text>
            <Text data-testid="subtitle" fontWeight="500" color="grey900" fontSize="14px">
              {data?.total} {textContent.product.productsTotal}
            </Text>
          </Flex>
        ) : (
          <Flex height="51px" alignItems="center">
            <ArrowBackIcon
              boxSize={8}
              marginRight="20px"
              onClick={() => {
                clearFilters();
                setSearchValue('');
              }}
              cursor="pointer"
              data-testid="back-button"
            />
            <Text
              color="darkBlue"
              fontSize={{ base: '18px', md: '20px' }}
              fontWeight="600"
              data-testid="back-button-title"
            >
              {textContent.product.products}
            </Text>
          </Flex>
        )}
      </Box>
      <Box padding="28px 112px">
        {TableContent}
        {!isLoading && data?.products?.length !== 0 && !data?.isComplete && (
          <Flex height="100%" alignItems="center" marginTop="10px">
            <AlertIcon color="#E53E3E" dataTestId="alert-icon" />
            <Text fontWeight="500" fontSize="14px" color="errorRed" marginLeft="8px">
              {textContent.product.productIsNotComplete}
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default ProductsView;
