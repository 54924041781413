import { mutate } from 'swr';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Image, Link, Text } from '@chakra-ui/react';

import HeartIcon from '../../../assets/Icons/HeartIcon';
import { priceListCodes } from '../../../constants';
import { ProductType } from '../../../interfaces';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { authData, getUserPriceList } from '../../../store/modules/auth';
import { getCartProductQuantity } from '../../../store/modules/cart/selectors';
import {
  addProductFavorite,
  deleteFavorite,
  isFavoriteProduct,
} from '../../../store/modules/favorites';
import { RootState } from '../../../store/types';
import { calculateRemainingStock } from '../../../utils/calculateRemainingStock';
import { priceFormatter } from '../../../utils/priceFormatter';
import { cutString, transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';
import FavoriteTooltip from '../../pages/ProductView/FavoriteTooltip';
import CardLabel from './CardLabel/CardLabel';
interface Props {
  product: ProductType;
  carouselTitle?: string;
  urlKey: string;
  isDeals?: boolean;
}

const ProductCard = ({ product, carouselTitle, urlKey, isDeals = false }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const { isLogged } = useAppSelector(authData);
  const [initialProductCardRender, setInitialProductCardRender] = useState(false);
  const userPriceList = useAppSelector(getUserPriceList);

  const favoriteProduct = useAppSelector((state: RootState) =>
    isFavoriteProduct(state, product?.articleCode as string),
  );

  mutate(urlKey, product.productImageUrl);

  const handleFavoriteProduct = () => {
    setInitialProductCardRender(true);

    if (!favoriteProduct) {
      dispatch(addProductFavorite(product));
      return;
    }
    dispatch(deleteFavorite({ productId: product.articleCode }));
  };

  useEffect(() => {
    if (initialProductCardRender) {
      setAlertIsOpen(true);
      setTimeout(() => {
        setAlertIsOpen(false);
      }, 1000);
    }
  }, [favoriteProduct]);

  const cartProductQuantity = useAppSelector((_state: RootState) =>
    getCartProductQuantity(_state, product.articleCode as string),
  );

  const availableStock = useMemo(() => {
    if (product) {
      const stock = calculateRemainingStock(product, cartProductQuantity);

      return stock;
    }
  }, [cartProductQuantity, product]);

  const noStock = Number(product.totalStock ?? '0') === 0;

  const RenderNoStock = useMemo(() => {
    return (
      <Flex
        justifyContent="center"
        flexWrap="wrap"
        flexDirection="column"
        alignItems="flex-start"
        minHeight="30px"
        width="100%"
        wordBreak="break-all"
      >
        <Text
          fontSize={{ base: '9px', xl: '12px' }}
          fontStyle="normal"
          fontWeight="400"
          lineHeight="15px"
          margin="10px 0 5px"
          wordBreak="break-all"
          width="auto"
        >
          {`${textContent.totalStock}:`}
        </Text>
        <Text
          fontSize={{ base: '10px', xl: '13px' }}
          fontStyle="normal"
          fontWeight="600"
          lineHeight="15px"
          marginBottom={{ base: '5px', xl: '10px' }}
        >
          {`${!noStock ? availableStock?.totalStock : textContent.noStock}`}
        </Text>
      </Flex>
    );
  }, [availableStock?.totalStock, noStock]);

  const cardLabelProps = () => {
    let hasDeals;

    const isSinsacate =
      userPriceList === priceListCodes.sinsacateMin ||
      userPriceList === priceListCodes.sinsacateMay;
    const isPocitos =
      userPriceList === priceListCodes.pocitosMin || userPriceList === priceListCodes.pocitosMay;

    if (isSinsacate) {
      hasDeals = product?.dealSinsacate === true && carouselTitle !== textContent.deals;
    } else if (isPocitos) {
      hasDeals = product?.dealPocitos === true && carouselTitle !== textContent.deals;
    }

    const isOnTravel = product.status === textContent.onTravelStatus;

    const props = { testId: 'no-stock-label', label: '', bgColor: '', textColor: '' };

    if (!hasDeals && !isOnTravel && noStock && isLogged) {
      props.testId = 'no-stock-label';
      props.label = textContent.noStock;
      props.bgColor = 'greyOnHover';
      props.textColor = 'black';
    }

    if (!hasDeals && isOnTravel && !isDeals) {
      props.testId = 'on-travel-label';
      props.label = transformString(textContent.onTravelStatus) || '';
      props.textColor = 'white';
      props.bgColor = 'blue';
    }

    if (hasDeals) {
      props.testId = 'deals-label';
      props.label = 'Oferta';
      props.bgColor = 'orange';
      props.textColor = 'white';
    }

    if (isOnTravel && isDeals) {
      props.testId = 'on-travel-label';
      props.label = transformString(textContent.onTravelStatus) || '';
      props.textColor = 'white';
      props.bgColor = 'blue';
    }

    return <CardLabel {...props} />;
  };

  return (
    <Flex
      position="relative"
      width={{ base: '140px ', xl: '185px' }}
      borderRadius="15px"
      background="#FEFEFE"
      flexDirection="column"
      paddingTop="8px"
      paddingLeft="13px"
      paddingRight="13px"
      justifyContent="space-between"
      data-testid="product-card-container"
      alignItems="center"
      margin="0 20px"
    >
      <Flex flexWrap="wrap">
        <Flex
          width={{ base: '120px', xl: '155px' }}
          height={{ base: '130px', xl: '160px' }}
          marginBottom="5px"
          borderRadius="9.59px"
          border="0.48px solid #D5DDE0"
          onClick={() => navigate(`/product/${product?.articleCode}`)}
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          data-testid="product-image-container"
          position="relative"
        >
          {isLogged && (
            <Flex position="absolute" top="5px" right="5px">
              <FavoriteTooltip bgIcon={favoriteProduct ? true : false} alertIsOpen={alertIsOpen}>
                <Flex>
                  <HeartIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      handleFavoriteProduct();
                    }}
                    dataTestId="heart-icon"
                    cursor="pointer"
                    bgIcon={favoriteProduct ? true : false}
                  />
                </Flex>
              </FavoriteTooltip>
            </Flex>
          )}
          <Image
            src={product.productImageUrl}
            alt={transformString(product.articleDesc)}
            width={{ base: '120px', xl: '100%' }}
            height={{ base: '128px', xl: '158px' }}
            borderRadius="9.59px"
            data-testid="product-image"
          />
        </Flex>
        <Flex
          display="flex"
          flexDirection="column"
          onClick={() => navigate(`/product/${product?.articleCode}`)}
          cursor="pointer"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
          data-testid="product-name-container"
          paddingBottom="5px"
          marginTop="10px"
          width="100%"
        >
          <Text
            fontSize={{ base: '12px', xl: '16px' }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
            whiteSpace="pre-wrap"
            minHeight="60px"
            width="100%"
          >
            {cutString(product?.articleDesc?.toUpperCase())}
          </Text>
        </Flex>
      </Flex>
      <Flex width="100%" justifyContent="flex-start" alignItems="flex-start">
        {isLogged && RenderNoStock}
      </Flex>
      {cardLabelProps()}
      <Flex flexDirection="column" alignItems="flex-start" alignContent="center" width="100%">
        {isLogged ? (
          <>
            <Text
              fontSize={{ base: '12px', xl: '16px' }}
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
              padding="5px 0"
              width="100%"
            >
              {`${priceFormatter.format(product?.priceList)}`}
            </Text>
            <Flex width="100%" justifyContent="center" alignItems="center">
              <Link
                onClick={() => navigate(`/product/${product?.articleCode}`)}
                isExternal
                data-testid="see-more-button"
                display="inline-flex"
                height="23px"
                padding="0px 8px"
                justifyContent="center"
                alignItems="center"
                flexShrink="0"
                color="white"
                backgroundColor="purple"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="16px"
                margin="5px 0 8px"
                width="98%"
                borderRadius="6px"
                style={{ textDecoration: 'none' }}
              >
                {textContent.seeMore}
              </Link>
            </Flex>
          </>
        ) : (
          <>
            <Flex width="100%" justifyContent="center" alignItems="center">
              <Link
                href={`https://wa.me/5493525521001?text=${textContent.checkPriceWhatsappMessage}`}
                isExternal
                data-testid="check-price-button"
                display="inline-flex"
                height="23px"
                padding="0px 8px"
                justifyContent="center"
                alignItems="center"
                flexShrink="0"
                color="purple"
                backgroundColor="background"
                border="1px solid"
                borderColor="purple"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="16px"
                margin="15px 0"
                width="100%"
                borderRadius="6px"
                style={{ textDecoration: 'none' }}
              >
                {textContent.checkPrice}
              </Link>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default ProductCard;
